import LandingPage from "./pages/LandingPage";
import React from "react";

const App = () => {
  return (
    <>
      <LandingPage />
    </>
  );
};

export default App;
