import "./LandingPage.css";

const LandingPage = () => {
  return (
    <>
      <div className="framer-div">
        <iframe
          src="https://braincade-staging.framer.website/"
          // scrolling="no"
          className="framer"
        />
      </div>
    </>
  );
};

export default LandingPage;
